import { getLogger, LoggerName } from "~/modules/logger";
import { LocalStorageKeys } from "../LocalStorage/LocalStorageKeys";

export const clearLocalStorage = () => () => {
    const logger = getLogger(LoggerName.Storage);
    logger.debug(`Clearing out Flex SDK local storage cache`);
    Object.values(LocalStorageKeys).forEach((key) => {
        localStorage.removeItem(key);
    });
};
