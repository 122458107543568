import { QueuesData } from "~/backend/generated/QueueStats/model/queuesData";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { QueueStatsQueuesProviderImpl } from "../QueueStatsQueuesProvider/QueueStatsChannelsProviderImpl/QueueStatsQueuesProviderImpl";

export const getQueueStatsQueues =
    (ctx: ContextManager) =>
    async (sessionToken: string, workspaceSid: string, queryParams?: string): Promise<QueuesData> => {
        const configProvider = QueueStatsQueuesProviderImpl(ctx);
        const config = await configProvider(sessionToken, workspaceSid, queryParams);
        return config;
    };
