import EventEmitter from "events";
import { Client, ClientConfigType, ClientEvent } from "~/modules/client";
import { Role } from "~/modules/auth";
import { Twilsock, TwilsockEvent } from "~/modules/websocket";
import { Session, SessionEvent } from "~/modules/session";
import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { createTelemetryClient, TelemetryClient, TelemetryClientFactory } from "~/modules/telemetry";
import {
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkClient,
    TelemetrySdkEventName,
    TelemetrySdkEventSource
} from "~/modules/telemetrySdkClient";
import { Emitter, proxyEvent } from "~/modules/events";
import { InsightsApis } from "~/modules/insights/historicalReporting/Definitions";
import { getTelemetrySdkClient } from "~/modules/telemetrySdkClient/telemetrySdkClient";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import { TwilsockImpl } from "~/modules/websocket/Twilsock/TwilsockImpl";
import { InsightsImpl } from "~/modules/insights/historicalReporting/InsightsImpl";
import { AccountConfigStore } from "~/modules/config/AccountConfig/AccountConfigImpl/AccountConfigStore/AccountConfigStore";
import { ProfileConnectorApis } from "~/modules/profileConnector/Definitions";
import { ProfileConnectorImpl } from "~/modules/profileConnector/ProfileConnectorImpl";
import { VirtualAgentDataApi } from "~/modules/virtualAgentData/Definitions";
import { VirtualAgentDataImpl } from "~/modules/virtualAgentData/VirtualAgentDataImpl";
import { InteractionContextApi } from "~/modules/interactionContext/Definitions";
import { InteractionContextImpl } from "~/modules/interactionContext/InteractionContextImpl";

export class ClientImpl implements Client {
    readonly #session: Session;

    readonly #connection: Twilsock;

    readonly #logger: Logger;

    readonly #telemetryClientFactory: TelemetryClientFactory<any>; 

    public readonly config: ClientConfigType;

    readonly #ctx: ContextManager;

    readonly #telemetrySdkClient: TelemetrySdkClient;

    readonly #emitter: Emitter;

    readonly #insightsApis: InsightsApis;

    readonly #profileConnector: ProfileConnectorApis;

    readonly #virtualAgentDataApi: VirtualAgentDataApi;

    readonly #interactionContextApi: InteractionContextApi;

    constructor(ctx: ContextManager) {
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#connection = ctx.getInstanceOf(TwilsockImpl);
        this.config = {
            account: ctx.getInstanceOf(AccountConfigStore)
        };
        this.#telemetryClientFactory = createTelemetryClient(ctx);
        this.#telemetrySdkClient = getTelemetrySdkClient(ctx);
        this.#logger = getLogger(LoggerName.Client);
        this.#emitter = new EventEmitter();
        this.#insightsApis = ctx.getInstanceOf(InsightsImpl);
        this.#profileConnector = ctx.getInstanceOf(ProfileConnectorImpl);
        this.#virtualAgentDataApi = ctx.getInstanceOf(VirtualAgentDataImpl);
        this.#interactionContextApi = ctx.getInstanceOf(InteractionContextImpl);
        this.#ctx = ctx;

        this.setupProxies();
    }

    setupProxies(): void {
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenAboutToExpire, ClientEvent.TokenAboutToExpire);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenExpired, ClientEvent.TokenExpired);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.TokenUpdated, ClientEvent.TokenUpdated);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.ConnectionError, ClientEvent.ConnectionLost);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.Connected, ClientEvent.ConnectionRestored);
        proxyEvent(this.#connection, this.#emitter, TwilsockEvent.Disconnected, ClientEvent.Disconnected);
        proxyEvent(this.#session, this.#emitter, SessionEvent.TokenAutoUpdateFailed, ClientEvent.TokenAutoUpdateFailed);
        proxyEvent(
            this.#session,
            this.#emitter,
            SessionEvent.TokenMaxLifetimeReached,
            ClientEvent.TokenMaxLifetimeReached
        );
    }

    async updateToken(token: string): Promise<void> {
        await this.#session.updateToken(token);
    }

    #sendDestroyEvent = async (): Promise<void> => {
        try {
            const telemetrySdkClient = this.#telemetrySdkClient;
            const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
            await group.addEvents({
                eventName: TelemetrySdkEventName.ClientDestroyed,
                eventSource: TelemetrySdkEventSource.Client
            });
        } catch (e) {
            this.#logger.error("Failed to send telemetry destroy event", e);
        }
    };

    async destroy(): Promise<void> {
        await this.#sendDestroyEvent();
        this.#logger.debug("client log out");
        await this.#session.destroy();
        this.#insightsApis.destroy();
        this.#emitter.removeAllListeners();
        this.#ctx.destroy();
    }

    get roles(): Array<Role> {
        return [...this.#session.roles];
    }

    get token(): string {
        return this.#session.token;
    }

    get insights(): InsightsApis {
        return this.#insightsApis;
    }

    get profileConnector(): ProfileConnectorApis {
        return this.#profileConnector;
    }

    get virtualAgentData(): VirtualAgentDataApi {
        return this.#virtualAgentDataApi;
    }

    get interactionContext(): InteractionContextApi {
        return this.#interactionContextApi;
    }

    createTelemetryClient<U extends object>(name: string): TelemetryClient<U> {
        return this.#telemetryClientFactory(name);
    }

    addListener(eventName: ClientEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.on(eventName, listener);
        return this;
    }

    removeListener(eventName: ClientEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.removeListener(eventName, listener);
        return this;
    }
}
