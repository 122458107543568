import { Client, ClientOptions } from "~/modules/client";
import { mergeUserOptions } from "~/utils/mergeUserOptions";
import { assertNotEmptyString } from "~/utils/assert";
import {
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkEventName,
    TelemetrySdkEventSource,
    TelemetrySdkClient,
    SDKDependency
} from "~/modules/telemetrySdkClient";

import { getLogger, Logger, LoggerName } from "~/modules/logger";
import { ErrorCode, ErrorSeverity } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { DeepPartial } from "~/utils/DeepPartial";
import { ClientOptionsStore } from "../ClientOptions/ClientOptionsStore";
import { throwFlexSdkError, throwFlexSdkErrorFromErrorResponse } from "~/modules/error/ThrowError/ErrorHelper";
import { getTelemetrySdkClient } from "~/modules/telemetrySdkClient/telemetrySdkClient";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import { ClientImpl } from "../Client/ClientImpl/ClientImpl";
import { initErrorSubscriptions } from "~/modules/reporter/Subscriber";

const sendClientInitEvent = async (logger: Logger, telemetrySdkClient: TelemetrySdkClient, durationInMs: number) => {
    try {
        const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
        await group.addEvents({
            eventName: TelemetrySdkEventName.ClientInitialized,
            eventSource: TelemetrySdkEventSource.Client,
            durationMs: durationInMs
        });
    } catch (e) {
        logger.error("Failed to send client init event", e);
    }
};

let instanceCreated: boolean;

export function resetClientCreated() {
    instanceCreated = false;
}

export const createClient =
    (ctx?: ContextManager) =>
    async (token: string, userOptions?: DeepPartial<ClientOptions>): Promise<Client> => {
        assertNotEmptyString(token, "token");

        let ctxInUse = ContextManager.newInstance();
        if (!instanceCreated) {
            ctxInUse = ctx || ctxInUse;
            instanceCreated = true;
        }

        initErrorSubscriptions(ctxInUse);

        const t0Ms = Date.now();
        const clientOptions = ctxInUse.getInstanceOf(ClientOptionsStore);
        const logger = getLogger(LoggerName.Client);
        const throwError = throwFlexSdkError(ctxInUse);
        const throwErrorFromErrorResponse = throwFlexSdkErrorFromErrorResponse(ctxInUse);

        mergeUserOptions(clientOptions, userOptions);

        const session = ctxInUse.getInstanceOf(SessionImpl);

        try {
            await session.init(token);
        } catch (err) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            
            
            if (err.code === 51102 || err.code === 20003) {
                metadata.source = "Twilsock";
                
                throwError(ErrorCode.Forbidden, metadata, "Insufficient permissions", err.source);
            } else {
                throwErrorFromErrorResponse(err, metadata);
            }
        }

        try {
            const telemetrySessionData = {
                dependencies: {
                    [SDKDependency.Twilsock]: "default"
                }
            };

            const telemetrySdkClient = getTelemetrySdkClient(ctxInUse);
            await telemetrySdkClient.setSessionData(telemetrySessionData);

            const durationMs = Date.now() - t0Ms;
            
            await sendClientInitEvent(logger, telemetrySdkClient, durationMs);
        } catch (e) {
            logger.error("Failed to set session data for telemetry", e);
        }

        const client = ctxInUse.getInstanceOf(ClientImpl);
        return client;
    };
