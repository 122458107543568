import { AccountConfigData, AccountConfigOptions } from "~/modules/config";
import { getLogger, LoggerName } from "~/modules/logger";
import { ErrorSeverity, ErrorCode } from "~/modules/error";
import { LocalStorageKeys } from "~/modules/storage";
import { ClientOptions } from "~/modules/client";
import { ConfigurationService } from "~/backend/generated/Configuration/api/configuration.serviceInterface";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { AccountConfigDataContainer } from "../AccountConfig/AccountConfigImpl/AccountConfigDataContainer/AccountConfigDataContainer";
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { LocalStorageImpl } from "~/modules/storage/LocalStorage/LocalStorageImpl";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import { ConfigurationServiceImpl } from "~/backend/generated/Configuration/api/configuration.service";
import { ContextManager } from "~/modules/contextManager/ContextManager";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};




export type AccountConfigProvider = (
    sessionToken: string,
    options?: AccountConfigOptions
) => Promise<AccountConfigData>;




export const accountConfigProvider =
    (ctx: ContextManager) =>
    async (sessionToken: string, options: AccountConfigOptions = {}): Promise<AccountConfigData> => {
        const dataContainer = ctx.getInstanceOf(AccountConfigDataContainer);
        const configurationService: ConfigurationService = ctx.getInstanceOf(ConfigurationServiceImpl);
        const throwAndReportError = throwFlexSdkError(ctx);
        const storage = ctx.getInstanceOf(LocalStorageImpl);
        const logger = getLogger(LoggerName.Config);
        const clientOptions: ClientOptions = ctx.getInstanceOf(ClientOptionsStore);
        const uiVersion = clientOptions?.accountConfig.query?.UiVersion || options?.query?.UiVersion;

        let configData: AccountConfigData;

        try {
            configData = await configurationService.fetchConfiguration("UiVersion", uiVersion, {
                token: sessionToken
            });
            storage.setCachedItem(LocalStorageKeys.AccountConfig, configData);
        } catch (err) {
            const cachedConfig = storage.getCachedItem<AccountConfigData>(LocalStorageKeys.AccountConfig);

            if (cachedConfig === undefined) {
                const message = `Failed to fetch account configuration: ${err}. No cache found.`;
                const errorCode = err.code || ErrorCode.Unknown;
                return throwAndReportError(errorCode, { ...metadata, severity: ErrorSeverity.Error }, message);
            }

            cachedConfig.dateCreated = new Date(cachedConfig.dateCreated);
            cachedConfig.dateUpdated = new Date(cachedConfig.dateUpdated);

            logger.warn(`Failed to fetch account configuration: ${err}. Using cache instead`);
            configData = cachedConfig;
        }
        dataContainer.set(configData);
        return configData;
    };
