export function parseRegionForTwilsock(region?: string): string {
    switch (region) {
        case "prod":
        case "":
        case undefined:
        case null:
            return "us1";
        case "stage":
            return "stage-us1";
        case "dev":
            return "dev-us1";
        default:
            return region;
    }
}

export function buildRegionalHost(region: string): string {
    switch (region) {
        case "prod":
        case "us1":
        case "":
            return "";
        case "dev-us1":
            return ".dev";
        case "stage-us1":
            return ".stage";
        default:
            return `.${region}`;
    }
}

export function buildRegionalHostWithEdge(region: string, edge: string): string {
    const applicableRegion = buildRegionalHost(region);
    let urlRegSpecifier = "";

    if (edge) {
        urlRegSpecifier += `.${edge}`;
    }

    if (applicableRegion) {
        urlRegSpecifier += `${applicableRegion}`;
    }
    return urlRegSpecifier;
}
