import { InteractionContextServiceImpl } from "~/backend/generated/InteractionContext/api/interactionContext.service";
import { InteractionContext } from "~/backend/generated/InteractionContext/model/interactionContext";
import { Session } from "~/modules/session";
import { getLogger, LoggerName } from "~/modules/logger";
import { InteractionContextApi, ProfileConnectorData } from "~/modules/interactionContext/Definitions";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";
import { AuthenticatorDataContainer } from "~/modules/auth/AuthenticatorDataContainer/AuthenticatorDataContainer";
import { AuthenticatorDataContainerImpl } from "~/modules/auth/AuthenticatorDataContainer/AuthenticatorDataContainerImpl";
import { Message } from "~/backend/generated/InteractionContext/model/message";





export class InteractionContextImpl implements InteractionContextApi {
    readonly #interactionContextService: InteractionContextServiceImpl;

    readonly #authenticatorDataContainer: AuthenticatorDataContainer;

    readonly #logger;

    readonly #session: Session;

    constructor(ctx: ContextManager) {
        this.#interactionContextService = ctx.getInstanceOf(InteractionContextServiceImpl);
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#logger = getLogger(LoggerName.InteractionContext);
        this.#logger.info("InteractionContextImpl created");
        this.#authenticatorDataContainer =
            ctx.getInstanceOf<AuthenticatorDataContainerImpl>(AuthenticatorDataContainerImpl);
    }

    getInteractionContext(lookupSid: string, agentViewable?: boolean): Promise<InteractionContext> {
        const token = this.#session.token;

        if (!token) {
            const errorMessage = "getInteractionContext called without token";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        if (!lookupSid) {
            const errorMessage = "getInteractionContext called without lookupSid";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        const accountSid = this.#authenticatorDataContainer.accountSid;
        return this.#interactionContextService.getContextByLookupSid(accountSid, lookupSid, agentViewable, {
            token
        });
    }

    async getProfileConnector(taskSid: string): Promise<ProfileConnectorData> {
        if (!taskSid) {
            const errorMessage = "getProfileConnector called without taskSid";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        const interactionContext: InteractionContext = await this.getInteractionContext(taskSid);
        if (!interactionContext || !interactionContext.attributes) {
            const errorMessage = "getProfileConnector couldn't find profile connector data";
            this.#logger.debug(errorMessage);
            return { taskSid };
        }

        
        const attributes: any = interactionContext.attributes;
        return {
            profileConnectSid: attributes?.ProfileData?.profileConnectSid?.value,
            connectorName: attributes?.ProfileData?.connectorName?.value,
            taskSid
        };
    }

    createInteractionContext(channel: string, formData: string): Promise<InteractionContext> {
        const token = this.#session.token;

        if (!token) {
            const errorMessage = "createInteractionContext called without token";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        if (!channel) {
            const errorMessage = "createInteractionContext called without channel";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        if (!formData) {
            const errorMessage = "createInteractionContext called without formData";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        const accountSid = this.#authenticatorDataContainer.accountSid;
        return this.#interactionContextService.createContext(accountSid, formData, { token });
    }

    addLookupSidToContext(lookupSid: string, contextSid: string): Promise<Message> {
        const token = this.#session.token;

        if (!token) {
            const errorMessage = "addLookupSidToContext called without token";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        if (!lookupSid) {
            const errorMessage = "addLookupSidToContext called without lookupSid";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        if (!contextSid) {
            const errorMessage = "addLookupSidToContext called without contextSid";
            this.#logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        const formData = new URLSearchParams();
        formData.append("ContextLookupSid", lookupSid);

        const accountSid = this.#authenticatorDataContainer.accountSid;
        return this.#interactionContextService.addLookupSidToContext(accountSid, contextSid, formData.toString(), {
            token
        });
    }
}
