












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { ChannelData } from "../model/channelData";
import { QueuesData } from "../model/queuesData";

import { QuequeStatsService }  from "./quequeStats.serviceInterface";

export class QuequeStatsServiceImpl implements QuequeStatsService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.simpleHttpAdapter = new SimpleHttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.simpleHttpAdapter.setIsEdgeSupported(false);
        
    }

    





    public async fetchChannels(workspaceSid: string, options?: object): Promise<ChannelData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (workspaceSid === null || workspaceSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter workspaceSid was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<ChannelData>(`https://taskrouter[region].twilio.com/v1/Workspaces/${encodeURIComponent(String(workspaceSid))}/TaskChannels`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `QuequeStatsService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    









    public async fetchChannelsByPage(workspaceSid: string, pageSize: 'PageSize', pageSizeValue: string, page: 'Page', pageValue: string, options?: object): Promise<ChannelData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (workspaceSid === null || workspaceSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter workspaceSid was null or undefined.");
        }
        if (pageSize === null || pageSize === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter pageSize was null or undefined.");
        }
        if (pageSizeValue === null || pageSizeValue === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter pageSizeValue was null or undefined.");
        }
        if (page === null || page === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter page was null or undefined.");
        }
        if (pageValue === null || pageValue === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter pageValue was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<ChannelData>(`https://taskrouter[region].twilio.com/v1/Workspaces/${encodeURIComponent(String(workspaceSid))}/TaskChannels?${encodeURIComponent(String(pageSize))}=${encodeURIComponent(String(pageSizeValue))}&${encodeURIComponent(String(page))}=${encodeURIComponent(String(pageValue))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `QuequeStatsService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    





    public async fetchQueues(workspaceSid: string, options?: object): Promise<QueuesData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (workspaceSid === null || workspaceSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter workspaceSid was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<QueuesData>(`https://taskrouter[region].twilio.com/v1/Workspaces/${encodeURIComponent(String(workspaceSid))}/TaskQueues`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `QuequeStatsService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    









    public async fetchQueuesByPage(workspaceSid: string, pageSize: 'PageSize', pageSizeValue: string, page: 'Page', pageValue: string, options?: object): Promise<QueuesData> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (workspaceSid === null || workspaceSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter workspaceSid was null or undefined.");
        }
        if (pageSize === null || pageSize === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter pageSize was null or undefined.");
        }
        if (pageSizeValue === null || pageSizeValue === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter pageSizeValue was null or undefined.");
        }
        if (page === null || page === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter page was null or undefined.");
        }
        if (pageValue === null || pageValue === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter pageValue was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<QueuesData>(`https://taskrouter[region].twilio.com/v1/Workspaces/${encodeURIComponent(String(workspaceSid))}/TaskQueues?${encodeURIComponent(String(pageSize))}=${encodeURIComponent(String(pageSizeValue))}&${encodeURIComponent(String(page))}=${encodeURIComponent(String(pageValue))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `QuequeStatsService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }
}

