import { WorkerConfigServiceImpl } from "~/backend/generated/Worker/api/workerConfig.service";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { ErrorCode, ErrorSeverity } from "~/modules/error";
import { throwAndReportFlexSdkError } from "~/modules/error/ThrowError/ThrowAndReportErrorHelper";
import { getLogger, LoggerName } from "~/modules/logger";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { WorkerConfigResponse } from "../WorkerProvider";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const WorkerProviderImpl =
    (ctx: ContextManager) =>
    async (sessionToken: string, workerConfig: string): Promise<WorkerConfigResponse> => {
        const workerConfigService = ctx.getInstanceOf(WorkerConfigServiceImpl);
        const throwAndReportError = throwAndReportFlexSdkError(ctx);
        const logger = getLogger(LoggerName.Config);

        let workerData: WorkerConfigResponse;

        try {
            workerData = await workerConfigService.updateWorkerConfig(workerConfig, { token: sessionToken });
        } catch (err) {
            logger.warn(`Failed to update worker config: ${err}`);
            const message = `Failed to update worker config: ${err}.`;
            const errorCode = err.code || ErrorCode.Unknown;
            return throwAndReportError(
                errorCode,
                { ...metadata, severity: ErrorSeverity.Error },
                message
            ) as unknown as Promise<WorkerConfigResponse>;
        }
        return workerData;
    };
