import { Observable, Subject } from "rxjs";
import { FlexSdkError } from "../error";
import { ContextManager } from "~/modules/contextManager/ContextManager";

export default class ErrorReporter extends Object {
    readonly #telemetryErrorSubject: Subject<FlexSdkError>;

    constructor(ctx: ContextManager) {
        super(ctx);
        this.#telemetryErrorSubject = new Subject<FlexSdkError>();
    }

    public get telemetryErrorStream(): Observable<FlexSdkError> {
        return this.#telemetryErrorSubject.asObservable();
    }

    publishTelemetryError(e: FlexSdkError): void {
        this.#telemetryErrorSubject.next(e);
    }
}
