












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { Plugin } from "../model/plugin";

import { PluginsService }  from "./plugins.serviceInterface";

export class PluginsServiceImpl implements PluginsService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.simpleHttpAdapter = new SimpleHttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.simpleHttpAdapter.setIsEdgeSupported(false);
        
    }

    




    public async fetchPlugins(options?: object): Promise<Array<Plugin>> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.simpleHttpAdapter.get<Array<Plugin>>(`https://flex[region].twilio.com/plugins`, AuthenticationMethod.HostedFlex, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `PluginsService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    






    public async fetchPluginsHosted(queryParamName?: string, queryParamValue?: string, options?: object): Promise<Array<Plugin>> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };

        let response;
        try {
            response = await this.simpleHttpAdapter.get<Array<Plugin>>(`https://flex[region].twilio.com/plugins?${encodeURIComponent(String(queryParamName))}=${encodeURIComponent(String(queryParamValue))}`, AuthenticationMethod.HostedFlex, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `PluginsService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }
}

