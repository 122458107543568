import { Plugin } from "~/backend/generated/Plugins/model/plugin";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { pluginsProviderImpl } from "../PluginsProvider/PluginsProviderImpl/PluginsProviderImpl";
import { PluginsConfigDataGetter } from "./PluginsConfigDataGetter";

export const getPluginsConfig: (ctx: ContextManager) => PluginsConfigDataGetter =
    (ctx: ContextManager) =>
    async (token: string, queryParams: string): Promise<Array<Plugin>> => {
        const pluginsConfigProvider = pluginsProviderImpl(ctx);
        const config = await pluginsConfigProvider(token, queryParams);
        return config;
    };
