import { Plugin } from "../PluginsProvider";
import { ErrorSeverity, ErrorCode } from "~/modules/error";
import { getLogger, LoggerName } from "~/modules/logger";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { throwAndReportFlexSdkError } from "~/modules/error/ThrowError/ThrowAndReportErrorHelper";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { PluginsServiceImpl } from "~/backend/generated/Plugins/api/plugins.service";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const pluginsProviderImpl =
    (ctx: ContextManager) =>
    async (sessionToken: string, queryParams?: string): Promise<Array<Plugin>> => {
        const pluginsService = ctx.getInstanceOf(PluginsServiceImpl);
        const throwAndReportError = throwAndReportFlexSdkError(ctx);
        const logger = getLogger(LoggerName.Config);

        let pluginsData: Array<Plugin>;

        try {
            if (queryParams) {
                const [queryProp, queryValue] = queryParams.split("=");
                pluginsData = await pluginsService.fetchPluginsHosted(queryProp, queryValue, { token: sessionToken });
            } else {
                pluginsData = await pluginsService.fetchPlugins({ token: sessionToken });
            }
        } catch (err) {
            logger.warn(`Failed to fetch plugins: ${err}`);
            const message = `Failed to fetch plugins: ${err}.`;
            const errorCode = err.code || ErrorCode.Unknown;
            return throwAndReportError(
                errorCode,
                { ...metadata, severity: ErrorSeverity.Error },
                message
            ) as unknown as Promise<never>;
        }
        return pluginsData;
    };
