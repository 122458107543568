
import { QuequeStatsServiceImpl } from "~/backend/generated/QueueStats/api/quequeStats.service";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { ErrorCode, ErrorSeverity } from "~/modules/error";
import { throwAndReportFlexSdkError } from "~/modules/error/ThrowError/ThrowAndReportErrorHelper";
import { getLogger, LoggerName } from "~/modules/logger";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { ChannelData } from "../QueueStatsChannelsProvider";

const metadata = {
    module: extractModuleFromPath(__dirname),
    eventSource: extractFileNameFromPath(__filename)
};

export const QueueStatsChannelsProviderImpl =
    (ctx: ContextManager) =>
    async (sessionToken: string, workspaceSid: string, queryParams?: string): Promise<ChannelData> => {
        const queueStatsService = ctx.getInstanceOf<QuequeStatsServiceImpl>(QuequeStatsServiceImpl);
        const throwAndReportError = throwAndReportFlexSdkError(ctx);
        const logger = getLogger(LoggerName.Config);

        let channelsData: ChannelData;

        try {
            if (queryParams) {
                const queryParamData = new URLSearchParams(queryParams);
                if (queryParamData.get("PageSize") && queryParamData.get("Page")) {
                    channelsData = await queueStatsService.fetchChannelsByPage(
                        workspaceSid,
                        "PageSize",
                        queryParamData.get("PageSize") || "",
                        "Page",
                        queryParamData.get("Page") || "",
                        { token: sessionToken }
                    );
                } else {
                    throw new Error("Invalid query Parameters");
                }
            } else {
                channelsData = await queueStatsService.fetchChannels(workspaceSid, { token: sessionToken });
            }
        } catch (err) {
            logger.warn(`Failed to fetch task channels: ${err}`);

            if (err.message === "Invalid query Parameters") {
                return throwAndReportError(
                    ErrorCode.InvalidParams,
                    { ...metadata, severity: ErrorSeverity.Error },
                    err.message
                ) as unknown as Promise<ChannelData>;
            }
            const message = `Failed to fetch task channels: ${err}.`;
            const errorCode = err.code || ErrorCode.Unknown;
            return throwAndReportError(
                errorCode,
                { ...metadata, severity: ErrorSeverity.Error },
                message
            ) as unknown as Promise<ChannelData>;
        }
        return channelsData;
    };
