import { EnvironmentConfig } from "~/modules/config";


declare namespace window {
    
    let __TWILIO_FLEX_SDK__: { environmentConfig: EnvironmentConfig } | undefined;
}

export const getEnvironmentConfig = (): EnvironmentConfig => ({
    twilioServiceLoginUrl: "https://flex.twilio.com/admin",
    ...window.__TWILIO_FLEX_SDK__?.environmentConfig
});
