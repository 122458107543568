
import packageInfo from "package.json";
import { createAuthenticator } from "~/modules/auth";
import { getLogger as getLoggerFromModule } from "~/modules/logger";
import { ContextManager } from "./modules/contextManager/ContextManager";
import { getTwilioIdpUrl as fromModuleGetTwilioIdpUrl } from "./modules/auth/TwilioIdpUrlFunction/getTwilioIdpUrl";
import { getAccountConfigData } from "./modules/config/AccountConfigDataGetter/getAccountConfigData";
import { createClient as fromModuleCreateClient } from "./modules/client/ClientProvider/createClient";
import { getPublicConfig as fromModuleGetPublicConfig } from "./modules/config/PublicConfigProvider/getPublicConfig";
import { getFeaturesConfig as fromModuleGetFeaturesConfig } from "./modules/config/FeaturesConfigDataGetter/getFeaturesConfig";
import { setWorkerConfig as fromModuleSetWorkingConfig } from "./modules/worker/WorkerConfigDataSetter/setWorkerConfig";
import { getPluginsConfig as fromModuleGetPluginsConfig } from "./modules/config/PluginsConfigDataGetter/getPluginsConfig";
import { getQueueStatsQueues } from "./modules/queueStats/QueueStatsQueuesDataGetter/getQueueStatsQueues";
import { getQueueStatsChannels } from "./modules/queueStats/QueueStatsChannelsDataGetter/getQueueStatsChannels";
import { clearLocalStorage } from "./modules/storage/ClearCache/clearLocalStorage";

export type { GDTokenEvents } from "~/modules/insights/historicalReporting/Definitions";
export type { SessionOptions } from "./modules/session";
export type { DeepPartial } from "./utils/DeepPartial";
export type { Without, XOR } from "./utils/utilityTypes";
export type {
    AccountConfig,
    AccountConfigProvider,
    AccountConfigKeys,
    AccountConfigData,
    IntegrationsConfig,
    OutboundFlows,
    SkillDefinition,
    QueueStatsConfiguration,
    PublicConfig,
    PublicConfigAttributes,
    PublicConfigProvider,
    AccountSidOption,
    RuntimeDomainOption,
    TaskQueue,
    WorkerChannel,
    DefaultQueueStatsConfiguration,
    QueueConfiguration,
    QueueChannelConfiguration,
    FeaturesConfigDataGetter,
    PluginsConfigDataGetter,
    Plugin,
    FeaturesConfig,
    FeaturesList,
    AccountConfigDataGetter,
    AccountConfigOptions,
    ChannelConfig,
    CbmAttachments,
    DebuggerIntegration,
    FlexUiStatusReport,
    CitrixVoiceVdi
} from "./modules/config";
export type { Logger, LoggerFactory, LoggerOptions, LoggerLevel, LoggerLevelNumbers } from "./modules/logger";
export { LoggerName } from "./modules/logger";
export type { Client, ClientProvider, ClientOptions, ClientConfigType } from "./modules/client";
export { ClientEvent } from "./modules/client";
export type {
    Authenticator,
    AuthenticatorFactory,
    TwilioIdpUrlFunction,
    IdpConfig,
    Role,
    TokenData,
    TokenRefreshResult
} from "./modules/auth";
export type { EventPublisher } from "./modules/events";
export type {
    TelemetryEvent,
    TelemetryErrorEvent,
    TelemetryEventGroup,
    TelemetryClient,
    TelemetryProcessingResult,
    TelemetryOptions
} from "./modules/telemetry";
export type { ClearCache } from "~/modules/storage";
export type {
    QueueStatsChannelsDataGetter,
    QueueStatsQueuesDataGetter,
    ChannelData,
    ChannelDataChannels,
    ChannelDataMeta,
    ChannelDataLinks,
    QueuesData,
    QueuesDataLinks,
    QueuesDataMeta,
    QueuesDataTaskQueues
} from "~/modules/queueStats";
export type {
    WorkerConfigDataSetter,
    WorkerConfigResponse,
    WorkerConfigResponseAttributes,
    WorkerConfigResponseAttributesDisabledSkills,
    WorkerConfigResponseAttributesRouting
} from "~/modules/worker";
export type {
    InsightsApis as Insights,
    InsightsUserRoles,
    SessionSuccess
} from "~/modules/insights/historicalReporting/Definitions";
export { hasPermission } from "~/modules/auth/Permission";
export type {
    VirtualAgentDataApi,
    VirtualAgentData,
    VirtualAgentDataSentences
} from "~/modules/virtualAgentData/Definitions";

export type {
    InteractionContextApi,
    InteractionContext,
    Message,
    ProfileConnectorData
} from "~/modules/interactionContext/Definitions";

export type {
    ProfileConnectorApis as ProfileConnector,
    ProfilesResponse,
    Profile,
    ProfileAddress,
    ProfileContact,
    ProfileAttribute,
    RankedProfile,
    FindProfilesRequest,
    IdentifiersResponse,
    TraitsResponse,
    Identifier,
    Trait
} from "~/modules/profileConnector/Definitions";


const ctx = ContextManager.newInstance();




export const auth = createAuthenticator(ctx);



export const getLogger = getLoggerFromModule;



export const getTwilioIdpUrl = (port?: string) => fromModuleGetTwilioIdpUrl(port);




export const getPublicConfig = fromModuleGetPublicConfig(ctx);




export const getAccountConfig = getAccountConfigData(ctx);




export const getFeaturesConfig = fromModuleGetFeaturesConfig(ctx);




export const setWorkerConfig = fromModuleSetWorkingConfig(ctx);




export const getPluginsConfig = fromModuleGetPluginsConfig(ctx);




export const getTaskChannels = getQueueStatsChannels(ctx);




export const getTaskQueues = getQueueStatsQueues(ctx);




export const createClient = fromModuleCreateClient(ctx);




export const clearCache = clearLocalStorage();




export const version: string = packageInfo.version;

export type { FlexSdkErrorMetadata } from "./modules/error";
export { FlexSdkError, ErrorCode, ErrorSeverity } from "./modules/error";
