import loglevel from "loglevel";
import packageInfo from "package.json";
import EventEmitter from "events";
import { Emitter } from "~/modules/events";
import { TwilsockClient, TwilsockClientEvent } from "~/modules/websocket/TwilsockClient/TwilsockClient";
import { Headers, Twilsock, TwilsockResult, TwilsockEvent } from "~/modules/websocket";
import {
    ErrorCode,
    FlexSdkError,
    ThrowErrorFunction,
    ThrowErrorFromErrorResponseFunction,
    InternalError,
    ErrorSeverity
} from "~/modules/error";
import { Logger, LoggerName, LoglevelMethodName, getLogger } from "~/modules/logger";
import { EnvironmentConfig } from "~/modules/config";
import { ClientOptions } from "~/modules/client";
import { retry } from "~/utils/retry/retry";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { DeepPartial } from "~/utils/DeepPartial";
import { isTwilsockReplyError, TwilsockReplyError } from "~/modules/websocket/Twilsock/TwilsockReplyError";
import { parseRegionForTwilsock } from "~/utils/regionUtil";
import { throwFlexSdkError, throwFlexSdkErrorFromErrorResponse } from "~/modules/error/ThrowError/ErrorHelper";
import { ClientOptionsStore } from "~/modules/client/ClientOptions/ClientOptionsStore";
import { getEnvironmentConfig } from "~/modules/config/EnvironmentConfig/EnvironmentConfigImpl";
import { getTwilsockClient } from "../TwilsockClientFactory/getTwilsockClient";
import { ContextManager } from "~/modules/contextManager/ContextManager";

const FLEX_SDK_NAME = "flex-sdk";
const FLEX_SDK_PLATFORM = "JS";
const PRODUCT_ID = "flex";

export class TwilsockImpl implements Twilsock {
    readonly #productId: string;

    readonly #twilsockClientFactory;

    private twilsockClient?: TwilsockClient;

    readonly #logger: Logger;

    readonly #environmentConfig: EnvironmentConfig;

    readonly #clientOptions: DeepPartial<ClientOptions>;

    #isConnected: boolean = false;

    readonly #emitter: Emitter;

    readonly #throwError: ThrowErrorFunction;

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    constructor(ctx: ContextManager) {
        this.#twilsockClientFactory = getTwilsockClient;
        this.#productId = PRODUCT_ID;
        this.#logger = getLogger(LoggerName.Twilsock);
        this.#logger.debug("Twilsock constructed");
        this.#environmentConfig = getEnvironmentConfig();
        this.#clientOptions = ctx.getInstanceOf(ClientOptionsStore);
        this.#emitter = new EventEmitter();

        this.#throwError = throwFlexSdkError(ctx);
        this.#throwErrorFromErrorResponse = throwFlexSdkErrorFromErrorResponse(ctx);
    }

    async connect(token: string): Promise<void> {
        if (this.#isConnected) {
            throw new InternalError("Twilsock connection already exists");
        }
        const { region, regionNonFlex } = this.#environmentConfig || {};
        const clientOptions = {
            region: parseRegionForTwilsock(
                regionNonFlex || this.#clientOptions.regionNonFlex || region || this.#clientOptions.region
            ),
            clientMetadata: {
                type: FLEX_SDK_NAME,
                sdk: FLEX_SDK_PLATFORM,
                sdkv: packageInfo.version,
                app: this.#clientOptions.appName,
                appv: this.#clientOptions.appVersion
            }
        };
        this.twilsockClient = this.#twilsockClientFactory(token, this.#productId, clientOptions);
        this.#proxyEventsFromTwilsockClient();
        this.#proxyLogsFromTwilsockClient();
        this.#connect0();
        await this.#waitUntilConnectedOrRejected();
    }

    #connect0(): void {
        if (this.#isConnected) {
            throw new InternalError("Twilsock connection already exists");
        }
        if (!this.twilsockClient) {
            throw new InternalError("TwilsockClient_is_null");
        }
        try {
            this.twilsockClient.connect();
            this.#isConnected = true;
        } catch (err) {
            this.#logger.warn("connection to twilsockClient failed", err);
        }
    }

    #proxyTwilsockClientEvent = (event: TwilsockClientEvent, alias: TwilsockEvent) => {
        this.getRawTwilsockClient().on(event, (...args: unknown[]) => this.#emitter.emit(alias, ...args));
    };

    #proxyEventsFromTwilsockClient = () => {
        this.#proxyTwilsockClientEvent(TwilsockClientEvent.TokenExpired, TwilsockEvent.TokenExpired);
        this.#proxyTwilsockClientEvent(TwilsockClientEvent.TokenAboutToExpire, TwilsockEvent.TokenAboutToExpire);
        this.#proxyTwilsockClientEvent(TwilsockClientEvent.StateChanged, TwilsockEvent.StateChanged);
        this.#proxyTwilsockClientEvent(TwilsockClientEvent.Connected, TwilsockEvent.Connected);
        this.#proxyTwilsockClientEvent(TwilsockClientEvent.Disconnected, TwilsockEvent.Disconnected);
        this.#listenAndEmitConnectionError();
    };

    #proxyLogsFromTwilsockClient = () => {
        const twilsockLogger = loglevel.getLogger("twilsock");
        twilsockLogger.methodFactory =
            (methodName: LoglevelMethodName) =>
            (...messages: unknown[]) => {
                return this.#logger[methodName](...messages);
            };
        twilsockLogger.setLevel("trace");
    };

    #listenAndEmitConnectionError = (): void => {
        this.getRawTwilsockClient().on(TwilsockClientEvent.ConnectionError, ({ errorCode, metadata, message }) => {
            const flexError = new FlexSdkError(errorCode || ErrorCode.TwilsockConnectionError, metadata, message);
            this.#emitter.emit(TwilsockEvent.ConnectionError, flexError);
        });
    };

    #isConnectionError = (error: Error): boolean => {
        return error instanceof FlexSdkError && error.code === ErrorCode.TwilsockConnectionError;
    };

    #isTooManyRequestsError = (error: Error): boolean => {
        const HTTP_STATUS_CODE_TOO_MANY_REQUESTS = 429;

        return (
            isTwilsockReplyError(error) &&
            (error as TwilsockReplyError).reply.status.code === HTTP_STATUS_CODE_TOO_MANY_REQUESTS
        );
    };

    #isServerError = (error: Error): boolean => {
        const HTTPS_STATUS_CODE_INTERNAL_SERVER_ERROR = 500;

        return (
            isTwilsockReplyError(error) &&
            (error as TwilsockReplyError).reply.status.code >= HTTPS_STATUS_CODE_INTERNAL_SERVER_ERROR
        );
    };

    #retryOnTooManyErrorOrConnectionErrorOrServerError = (error: Error): boolean => {
        return this.#isTooManyRequestsError(error) || this.#isServerError(error) || this.#isConnectionError(error);
    };

    #updateTokenOnce = async (token: string): Promise<void> => {
        if (!this.#isConnected) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };

            this.#throwError(ErrorCode.InvalidState, metadata, "no twilsock client");
        } else {
            if (!this.twilsockClient) {
                throw new InternalError("TwilsockClient_is_null");
            }
            await this.twilsockClient.updateToken(token);
            this.#emitter.emit(TwilsockEvent.TokenUpdated, token);
        }
    };

    async updateToken(token: string): Promise<void> {
        try {
            await retry<void>({
                functionToRetry: () => this.#updateTokenOnce(token),
                retryCondition: this.#retryOnTooManyErrorOrConnectionErrorOrServerError,
                initialDelay: 500,
                logger: this.#logger
            });
        } catch (error) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: "update Twilsock token"
            };

            this.#throwErrorFromErrorResponse(error, metadata);
        }
    }

    #waitUntilConnectedOrRejected = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            const successHandler = () => {
                return resolve();
            };

            const connectionErrorHandler = (error: FlexSdkError) => {
                this.#isConnected = false;
                return reject(error);
            };

            const removeConnectionListeners = () => {
                this.removeListener(TwilsockEvent.Connected, successHandler);
                this.removeListener(TwilsockEvent.ConnectionError, connectionErrorHandler);
            };

            this.addListener(TwilsockEvent.Connected, () => {
                removeConnectionListeners();
                successHandler();
            });
            this.addListener(TwilsockEvent.ConnectionError, (error: FlexSdkError) => {
                removeConnectionListeners();
                connectionErrorHandler(error);
            });

            const rawTwilsockClient = this.getRawTwilsockClient();
            if (rawTwilsockClient.isConnected) {
                resolve();
            }
        });
    };

    getRawTwilsockClient(): TwilsockClient {
        if (!this.twilsockClient) {
            throw new InternalError("Twilsock hasn't been initialized");
        }
        return this.twilsockClient;
    }

    async post<T>(url: string, headers: Headers, body: object): Promise<TwilsockResult<T>> {
        if (!this.#isConnected) {
            await this.getTwilsockClientConnected();
        }
        try {
            return await this.getRawTwilsockClient().post(url, headers, body);
        } catch (error) {
            const code: number = error.body?.code || ErrorCode.SDK;
            const message: string = error.body?.message || error.message;
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };

            return this.#throwError(code, metadata, message, error) as unknown as Promise<never>;
        }
    }

    async destroy(): Promise<void> {
        this.#isConnected = false;
        if (!this.twilsockClient) {
            this.#logger.warn("[TwilsockImpl.destroy] - TwilsockClient_is_null");
            return;
        }

        const connectionDestroyed = new Promise((resolve) => {
            if (!this.twilsockClient) {
                return;
            }
            this.twilsockClient.on(TwilsockClientEvent.Disconnected, resolve);
        });

        await this.twilsockClient.disconnect();
        await connectionDestroyed;

        
        
        delete this.twilsockClient;
        this.#emitter.removeAllListeners();
    }

    addListener(eventName: TwilsockEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.on(eventName, listener);
        return this;
    }

    removeListener(eventName: TwilsockEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.removeListener(eventName, listener);
        return this;
    }

    isConnected(): boolean {
        if (!this.#isConnected) {
            return false;
        }
        if (!this.twilsockClient) {
            return false;
        }

        return this.getRawTwilsockClient().isConnected;
    }

    async getTwilsockClientConnected(): Promise<void> {
        this.#connect0();
        await this.#waitUntilConnectedOrRejected();
    }
}






