import { VirtualAgentDataServiceImpl } from "~/backend/generated/VirtualAgentData/api/virtualAgentData.service";
import { VirtualAgentData } from "~/backend/generated/VirtualAgentData/model/virtualAgentData";
import { Session } from "~/modules/session";
import { getLogger, LoggerName } from "~/modules/logger";
import { VirtualAgentDataApi } from "~/modules/virtualAgentData/Definitions";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { SessionImpl } from "~/modules/session/Session/SessionImpl";





export class VirtualAgentDataImpl implements VirtualAgentDataApi {
    readonly #virtualAgentDataService: VirtualAgentDataServiceImpl;

    readonly #logger;

    readonly #session: Session;

    constructor(ctx: ContextManager) {
        this.#virtualAgentDataService = ctx.getInstanceOf(VirtualAgentDataServiceImpl);
        this.#session = ctx.getInstanceOf(SessionImpl);
        this.#logger = getLogger(LoggerName.VirtualAgentData);
        this.#logger.info("VirtualAgentImpl created!");
    }

    getV1VirtualAgentData(contextLookupSid: string): Promise<VirtualAgentData> {
        
        const token = this.#session.token;

        if (!token) {
            this.#logger.error("getV1VirtualAgentData called without token");
            throw new Error("getV1VirtualAgentData called without token");
        }

        if (!contextLookupSid) {
            this.#logger.error("getV1VirtualAgentData called without contextLookupSid");
            throw new Error("getV1VirtualAgentData called without contextLookupSid");
        }

        return this.#virtualAgentDataService.getV1VirtualAgentData("ContextLookupSid", contextLookupSid, { token });
    }
}
