import twilsockLoglevel from "loglevels/twilsock";
import * as defaultLoglevel from "loglevel";
import { LoggerPlugin } from "./LoggerFactory";
import { assertString } from "~/utils/assert";
import { InternalLoggerName, SdkLoggerName } from "~/modules/logger/Logger/LoggerName";
import { logEntryPlugin } from "../plugins/logEntryPlugin";
import { LoggerImpl } from "../Logger/LoggerImpl/LoggerImpl";
import { Logger, LoggerOptions, Loglevel } from "../Logger/Logger";

export const getDefaultLoggerOptions: () => LoggerOptions = () => ({
    level: "ERROR"
});

const sdkLoggerNameToLoglevel: { [name in SdkLoggerName]: defaultLoglevel.RootLogger } = {
    [SdkLoggerName.Twilsock]: twilsockLoglevel
};

const getSdkLoglevel = (name: SdkLoggerName) => {
    return sdkLoggerNameToLoglevel[name].getLogger(name);
};

const loggerMap = new Map();

export const getLogger = (name: InternalLoggerName | string): Logger => {
    assertString(name, "name");

    let logger = loggerMap.get(name);
    if (logger) {
        return logger;
    }

    const defaultLevel = getDefaultLoggerOptions().level;
    const plugins = [logEntryPlugin];

    let loglevel;
    if (Object.keys(sdkLoggerNameToLoglevel).includes(name)) {
        loglevel = getSdkLoglevel(name as SdkLoggerName);
    } else {
        loglevel = defaultLoglevel.getLogger(name);
    }

    loglevel = plugins.reduce((instance: Loglevel, plugin: LoggerPlugin) => {
        return plugin(instance);
    }, loglevel);

    logger = new LoggerImpl(loglevel);
    logger.setLevel(defaultLevel);
    loggerMap.set(name, logger);
    return logger;
};
