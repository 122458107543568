import { SSOLoginRequestBody } from "~/backend/generated/FederatedAuth/model/sSOLoginRequestBody";
import { IdpConfig } from "~/modules/auth";






function sanitizeSSOParams(s: string): string {
    return s.replace(/((Token|Expiration|Identity|Roles)=[^&]+[&]?)/g, "").replace(/[&?]$/, "");
}

export function getSSOLoginRequestBody(config: IdpConfig): SSOLoginRequestBody {
    return {
        products: ["flex"],
        resource: sanitizeSSOParams(config.redirectUrl)
    };
}
