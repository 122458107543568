import { ClientOptions } from "~/modules/client";
import { SessionOptions } from "~/modules/session";
import { LoggerOptions } from "~/modules/logger";
import { TelemetryOptions } from "~/modules/telemetry";
import { AccountConfigOptions } from "~/modules/config";
import { getDefaultLoggerOptions } from "~/modules/logger/LoggerFactory/getLogger";
import { getDefaultSessionOptions } from "~/modules/session/SessionOptions/defaultSessionOptions";
import { getDefaultTelemetryOptions } from "~/modules/telemetry/TelemetryOptions/getDefaultTelemetryOptions";
import { getDefaultAccountConfigOptions } from "~/modules/config/AccountConfigOptions/getDefaultAccountConfigOptions";


export class ClientOptionsStore implements ClientOptions {
    readonly #session: SessionOptions;

    readonly #logger: LoggerOptions;

    #telemetry: TelemetryOptions;

    readonly #accountConfig: AccountConfigOptions;

    
    
    #region: string;

    
    
    #regionNonFlex: string;

    #appName: string;

    #appVersion: string;

    constructor() {
        this.#session = getDefaultSessionOptions();
        this.#logger = getDefaultLoggerOptions();
        this.#telemetry = getDefaultTelemetryOptions();
        this.#accountConfig = getDefaultAccountConfigOptions();
    }

    get session(): SessionOptions {
        return this.#session;
    }

    get logger(): LoggerOptions {
        return this.#logger;
    }

    get telemetry(): TelemetryOptions {
        return this.#telemetry;
    }

    set telemetry(telemetry: TelemetryOptions) {
        this.#telemetry = telemetry;
    }

    get accountConfig(): AccountConfigOptions {
        return this.#accountConfig;
    }

    get region(): string {
        return this.#region;
    }

    set region(value: string) {
        this.#region = value;
    }

    get regionNonFlex(): string {
        return this.#regionNonFlex;
    }

    set regionNonFlex(value: string) {
        this.#regionNonFlex = value;
    }

    get appName(): string {
        return this.#appName;
    }

    set appName(value: string) {
        this.#appName = value;
    }

    get appVersion(): string {
        return this.#appVersion;
    }

    set appVersion(value: string) {
        this.#appVersion = value;
    }
}

let instance: ClientOptionsStore;
export const getClientOptionsStoreInstance: () => ClientOptionsStore = () => {
    instance = instance || new ClientOptionsStore();
    return instance;
};
