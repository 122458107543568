import { ChannelData } from "~/backend/generated/QueueStats/model/channelData";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { QueueStatsChannelsProviderImpl } from "../QueueStatsChannelsProvider/QueueStatsChannelsProviderImpl/QueueStatsChannelsProviderImpl";

export const getQueueStatsChannels =
    (ctx: ContextManager) =>
    async (sessionToken: string, workspaceSid: string, queryParams?: string): Promise<ChannelData> => {
        const configProvider = QueueStatsChannelsProviderImpl(ctx);
        const config = await configProvider(sessionToken, workspaceSid, queryParams);
        return config;
    };
