import React from "react";
import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
import * as Flex from "@twilio/flex-ui";
import {Actions, QueuesStats} from "@twilio/flex-ui";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

const mountNode = document.getElementById("root");
const microserviceApiUrl = 'https://flex-prod.microservice.myrecoverylink.com/api';

window.onload = () => {
    const predefinedConfig = window.appConfig || {};

    const configuration = {
        ...predefinedConfig,
    };

    Flex
        .progress(mountNode)
        .provideLoginInfo(configuration, mountNode)
        .then(() => Flex.Manager.create(configuration))
        .then(manager => {
            renderApp(manager)
        })
        .catch(error => handleError(error));

    Flex.MainHeader
        .defaultProps
        .logoUrl = "/assets/RL_flex.svg";
};

function handleOrganizationQueues(result) {
    let Organization = result.organization;

    console.log('Organization', Organization);

    switch (Organization) {
        case 'RecoveryLink':
            let rlQueues = ['RecoveryLink - Sales', 'RecoveryLink - Tech Support', 'RecoveryLink - Peer Support'];

            QueuesStats.setFilter((queue) => rlQueues.includes(queue.friendly_name))
            Flex.TeamsView.defaultProps.hiddenFilter = 'data.attributes.organization CONTAINS "RecoveryLink"'
            break;
        case 'Texas':
            var queueNames = [
                "Texas - Peer Support for Self",
                "Texas - Peer Support for Someone Else",
                "Texas - Peer Support Crisis",
                "Texas - Peer Support Treatment",
                "BeWell Texas - Spanish Requirement",
                "BeWell Texas - Front Office",
                "BeWell Texas - Lab Work",
                "BeWell Texas - Crisis Support",
                "BeWell Texas - Peer Support",
                "BeWell Texas - External Transfer - English",
                "BeWell Texas - External Transfer - Spanish"
            ];

            QueuesStats.setFilter((queue) => queueNames.includes(queue.friendly_name))
            Flex.TeamsView.defaultProps.hiddenFilter = 'data.attributes.organization CONTAINS "Texas"'
            break;
        default:
            break;
    }
}

function handleNoOrganizationFound() {
    alert(`This Flex user is missing critical information required to do advanced functionality. Please contact your administrator to have them configure your profile.
Features unavailable:
- External calling
- Team and queue statistics / monitoring`);

    QueuesStats.setFilter((queue) => false)
}

function render(manager) {
    ReactDOM.render(<
            App manager={
            manager
        }
        />,
        mountNode
    );
}

function renderApp(manager) {
    const userEmail = manager.workerClient.attributes.email;

    console.log('manager', manager)

    fetch(`${microserviceApiUrl}/users/${userEmail}`)
        .then(res => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error('Something went wrong');
            }
        })
        .catch(() => {
            QueuesStats.setFilter(() => false)
            Flex.TeamsView.defaultProps.hiddenFilter = 'data.attributes.organization CONTAINS "false"'
        })
        .then((result) => {
            if (result == null || result.organization == null) {
                handleNoOrganizationFound();
            } else {
                handleOrganizationQueues(result);
            }
        })
        .finally(() => {
            render(manager);
        });
}

function handleError(error) {
    Flex.errorPage(error, mountNode);
    console.error("Failed to initialize Flex", error);
}

registerServiceWorker();
