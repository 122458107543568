












import { ErrorCode, ErrorSeverity, ThrowErrorFunction, isDownstreamErrorCode } from "~/modules/error";
import { HttpAdapter } from "~/backend/HttpAdapter/HttpAdapter";
import { AuthenticationMethod } from "~/backend/commons/authenticationMethods";
import { extractFileNameFromPath } from "~/utils/extractFromPath"
import { throwFlexSdkError } from "~/modules/error/ThrowError/ErrorHelper";
import { SimpleHttpAdapterImpl } from "~/backend/SimpleHttpAdapter/SimpleHttpAdapterImpl";
import { ContextManager } from "~/modules/contextManager/ContextManager";

import { InteractionContext } from "../model/interactionContext";
import { Message } from "../model/message";

import { InteractionContextService }  from "./interactionContext.serviceInterface";

export class InteractionContextServiceImpl implements InteractionContextService {
    private simpleHttpAdapter: HttpAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(ctx: ContextManager) {
        this.simpleHttpAdapter = new SimpleHttpAdapterImpl(ctx);
        this.#throwError = throwFlexSdkError(ctx);
        this.simpleHttpAdapter.setIsEdgeSupported();
        
    }

    







    public async addLookupSidToContext(accountSid: string, contextSid: string, body: string, options?: object): Promise<Message> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (contextSid === null || contextSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter contextSid was null or undefined.");
        }
        if (body === null || body === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter body was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.post<Message>(`https://flex-api[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Contexts/${encodeURIComponent(String(contextSid))}/ContextLookupSids`, AuthenticationMethod.Basic, body, options, "application/x-www-form-urlencoded");
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `InteractionContextService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    






    public async createContext(accountSid: string, body: string, options?: object): Promise<InteractionContext> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (body === null || body === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter body was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.post<InteractionContext>(`https://flex-api[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Contexts`, AuthenticationMethod.Basic, body, options, "application/x-www-form-urlencoded");
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `InteractionContextService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    







    public async getContextByLookupSid(accountSid: string, contextLookupSid: string, agentViewable?: boolean, options?: object): Promise<InteractionContext> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (contextLookupSid === null || contextLookupSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter contextLookupSid was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<InteractionContext>(`https://flex-api[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Contexts?ContextLookupSid=${encodeURIComponent(String(contextLookupSid))}`, AuthenticationMethod.Basic, options);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `InteractionContextService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }

    






    public async getContextBySid(accountSid: string, contextSid: string): Promise<InteractionContext> {
        const metadata = {
            module: "backend",
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        if (accountSid === null || accountSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter accountSid was null or undefined.");
        }
        if (contextSid === null || contextSid === undefined) {
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter contextSid was null or undefined.");
        }

        let response;
        try {
            response = await this.simpleHttpAdapter.get<InteractionContext>(`https://flex-api[region].twilio.com/v1/Accounts/${encodeURIComponent(String(accountSid))}/Contexts/${encodeURIComponent(String(contextSid))}`, undefined);
        } catch (e) {
            if (isDownstreamErrorCode(e.code)) {
                const message = `InteractionContextService - ${e.message}`
                return this.#throwError(ErrorCode.DownstreamServiceError, metadata, message) as unknown as Promise<never>;;
            }

            return Promise.reject(e)
        }
        return response;
    }
}

