import { WorkerConfigResponse } from "~/backend/generated/Worker/model/workerConfigResponse";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { WorkerProviderImpl } from "../WorkerProvider/WorkerProviderImpl/WorkerProviderImpl";

export const setWorkerConfig =
    (ctx: ContextManager) =>
    async (sessionToken: string, workerConfig: string): Promise<WorkerConfigResponse> => {
        const configProvider = WorkerProviderImpl(ctx);
        const config = await configProvider(sessionToken, workerConfig);
        return config;
    };
