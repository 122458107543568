import { Authenticator } from "~/modules/auth";
import { ContextManager } from "~/modules/contextManager/ContextManager";
import { FederatedAuth } from "../../Authenticator/FederatedAuth/FederatedAuth";
import { AuthenticatorDataContainerImpl } from "../../AuthenticatorDataContainer/AuthenticatorDataContainerImpl";

export const createAuthenticator =
    (ctx: ContextManager) =>
    (accountSid: string): Authenticator => {
        const authenticatorDataContainer = ctx.getInstanceOf(AuthenticatorDataContainerImpl);
        authenticatorDataContainer.accountSid = accountSid;

        const auth = new FederatedAuth(ctx);
        return auth;
    };
