import { ContextManager } from "~/modules/contextManager/ContextManager";
import { getLogger, Logger, TelemetryLoggerName } from "~/modules/logger";
import { TelemetryClient, TelemetryEventGroup } from "~/modules/telemetry";
import { createEventGroup } from "~/modules/telemetry/TelemetryEventGroupFactory/createEventGroup";
import { TelemetryEventGroupFactory } from "~/modules/telemetry/TelemetryEventGroupFactory/TelemetryEventGroupFactory";

export class TelemetryClientImpl<U extends object> implements TelemetryClient<U> {
    name: string;

    sessionData?: U;

    readonly #eventGroupFactory: TelemetryEventGroupFactory<unknown>;

    readonly #logger: Logger;

    constructor(ctx: ContextManager) {
        this.#eventGroupFactory = createEventGroup(ctx);
        this.#logger = getLogger(TelemetryLoggerName.TelemetryClient);
    }

    createEventGroup<T>(name?: string): TelemetryEventGroup<T> {
        this.#logger.debug("creating event group with name: ", name);
        const eventGroup = this.#eventGroupFactory(this, name);
        return eventGroup;
    }

    
    
    async setSessionData(sessionData: U): Promise<void> {
        this.sessionData = sessionData;
    }
}
